HandlebarsIntl.__addLocaleData({"locale":"pt","pluralRuleFunction":function (n,ord){var s=String(n).split("."),t0=Number(s[0])==n;if(ord)return"other";return t0&&n>=0&&n<=2&&n!=2?"one":"other"},"fields":{"year":{"displayName":"Ano","relative":{"0":"este ano","1":"próximo ano","-1":"ano passado"},"relativeTime":{"future":{"one":"Dentro de {0} ano","other":"Dentro de {0} anos"},"past":{"one":"Há {0} ano","other":"Há {0} anos"}}},"month":{"displayName":"Mês","relative":{"0":"este mês","1":"próximo mês","-1":"mês passado"},"relativeTime":{"future":{"one":"Dentro de {0} mês","other":"Dentro de {0} meses"},"past":{"one":"Há {0} mês","other":"Há {0} meses"}}},"day":{"displayName":"Dia","relative":{"0":"hoje","1":"amanhã","2":"depois de amanhã","-1":"ontem","-2":"anteontem"},"relativeTime":{"future":{"one":"Dentro de {0} dia","other":"Dentro de {0} dias"},"past":{"one":"Há {0} dia","other":"Há {0} dias"}}},"hour":{"displayName":"Hora","relativeTime":{"future":{"one":"Dentro de {0} hora","other":"Dentro de {0} horas"},"past":{"one":"Há {0} hora","other":"Há {0} horas"}}},"minute":{"displayName":"Minuto","relativeTime":{"future":{"one":"Dentro de {0} minuto","other":"Dentro de {0} minutos"},"past":{"one":"Há {0} minuto","other":"Há {0} minutos"}}},"second":{"displayName":"Segundo","relative":{"0":"agora"},"relativeTime":{"future":{"one":"Dentro de {0} segundo","other":"Dentro de {0} segundos"},"past":{"one":"Há {0} segundo","other":"Há {0} segundos"}}}}});
HandlebarsIntl.__addLocaleData({"locale":"pt-AO","parentLocale":"pt-PT"});
HandlebarsIntl.__addLocaleData({"locale":"pt-PT","parentLocale":"pt","pluralRuleFunction":function (n,ord){var s=String(n).split("."),v0=!s[1];if(ord)return"other";return n==1&&v0?"one":"other"},"fields":{"year":{"displayName":"Ano","relative":{"0":"este ano","1":"próximo ano","-1":"ano passado"},"relativeTime":{"future":{"one":"dentro de {0} ano","other":"dentro de {0} anos"},"past":{"one":"há {0} ano","other":"há {0} anos"}}},"month":{"displayName":"Mês","relative":{"0":"este mês","1":"próximo mês","-1":"mês passado"},"relativeTime":{"future":{"one":"dentro de {0} mês","other":"dentro de {0} meses"},"past":{"one":"há {0} mês","other":"há {0} meses"}}},"day":{"displayName":"Dia","relative":{"0":"hoje","1":"amanhã","2":"depois de amanhã","-1":"ontem","-2":"anteontem"},"relativeTime":{"future":{"one":"dentro de {0} dia","other":"dentro de {0} dias"},"past":{"one":"há {0} dia","other":"há {0} dias"}}},"hour":{"displayName":"Hora","relativeTime":{"future":{"one":"dentro de {0} hora","other":"dentro de {0} horas"},"past":{"one":"há {0} hora","other":"há {0} horas"}}},"minute":{"displayName":"Minuto","relativeTime":{"future":{"one":"dentro de {0} minuto","other":"dentro de {0} minutos"},"past":{"one":"há {0} minuto","other":"há {0} minutos"}}},"second":{"displayName":"Segundo","relative":{"0":"agora"},"relativeTime":{"future":{"one":"dentro de {0} segundo","other":"dentro de {0} segundos"},"past":{"one":"há {0} segundo","other":"há {0} segundos"}}}}});
HandlebarsIntl.__addLocaleData({"locale":"pt-BR","parentLocale":"pt"});
HandlebarsIntl.__addLocaleData({"locale":"pt-CV","parentLocale":"pt-PT"});
HandlebarsIntl.__addLocaleData({"locale":"pt-GW","parentLocale":"pt-PT"});
HandlebarsIntl.__addLocaleData({"locale":"pt-MO","parentLocale":"pt-PT"});
HandlebarsIntl.__addLocaleData({"locale":"pt-MZ","parentLocale":"pt-PT"});
HandlebarsIntl.__addLocaleData({"locale":"pt-ST","parentLocale":"pt-PT"});
HandlebarsIntl.__addLocaleData({"locale":"pt-TL","parentLocale":"pt-PT"});
